<template>
    <!--@formatter:off-->
    <component :is="tag" @click.prevent="onClickBottomSheetButton">
        <slot :selectedLabel="selectedLabel"></slot>
        <select v-if="!isMobile" v-model="innerValue">
            <option
                v-for="option in options"
                :key="`option-${option.value}`"
                :value="option.value"
            >
                {{option.label}}
            </option>
        </select>
    </component>
    <!--@formatter:on-->
</template>

<script>
import {isMobile} from '@common/utils/checkMobileDevice.js';
import {bottomSheetScheme} from '@common/utils/webview.js';

const FUNC_NAME_GET_OPTIONS = '__BOTTOM_SHEET__getOptions';
const FUNC_NAME_GET_RESULT = '__BOTTOM_SHEET__getResult';
const bottomSheetInstances = {};

window[FUNC_NAME_GET_OPTIONS] = (id) => {
    const {title, labels, selectedIndex} = bottomSheetInstances[id].storeData;

    //todo remove
    console.info(`call ${FUNC_NAME_GET_OPTIONS}, id: `, id);
    console.info(`return:`, {title, labels, selectedIndex});

    return JSON.stringify({
        title,
        labels,
        selectedIndex,
    });
};
window[FUNC_NAME_GET_RESULT] = (id, selectedIndex) => {
    const {options, resultCallBackFunc} = bottomSheetInstances[id].storeData;

    //todo remove
    console.info(`call ${FUNC_NAME_GET_RESULT}, id: ${id}, selectedIndex: ${selectedIndex}`);

    resultCallBackFunc(options[selectedIndex]?.value);
    return;
};

export default {
    name: 'BottomSheet',
    props: {
        tag: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        value: {},
        options: {
            type: Array,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            innerValue: this.value,
            isMobile: isMobile(),
            resultCallBackFunc: () => {
            },
        };
    },
    watch: {
        innerValue(value) {
            this.$emit('input', value);
        },

        /**
         * 컴포넌트 외부에서 value 값이 바뀐경우 innerValue 함께 변경해준다.
         * @param newValue
         */
        value(newValue) {
            this.innerValue = newValue;
        },
    },
    computed: {
        storeData() {
            return {
                id: this._uid,
                title: this.title,
                options: this.options,
                innerValue: this.innerValue,
                labels: this.options.map(({label}) => label),
                selectedIndex: this.options.findIndex(({value}) => value === this.innerValue),
                resultCallBackFunc: this.resultCallBackFunc,
            };
        },

        selectedLabel() {
            return this.options.find(({label, value}) => value === this.innerValue)?.label;
        },
    },
    methods: {
        async onClickBottomSheetButton() {
            if (this.disabled) {
                return;
            }
            this.$emit('onClickBottomSheetButton');

            const id = this._uid;
            const value = await this.openBottomSheet(id);
            if (value !== undefined) {
                this.innerValue = value;
            }
        },
        openBottomSheet(id) {
            return new Promise(((resolve) => {
                this.resultCallBackFunc = (value) => resolve(value);
                bottomSheetScheme({
                    getFuncName: FUNC_NAME_GET_OPTIONS,
                    resultFuncName: FUNC_NAME_GET_RESULT,
                    id,
                });
            }));
        },
    },
    created() {
        bottomSheetInstances[`${this._uid}`] = this;
    },
    beforeDestroy() {
        bottomSheetInstances[`${this._uid}`] = undefined;
    },
};
</script>

<style scoped>
select {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 50px;
}
</style>
