<template>
    <!--@formatter:off-->
    <div class="wrap">
        <b>Bottom TEST</b>
        <BottomSheet
            tag="button"
            :title="title"
            :options="options"
            v-model="year"
        >
            바텀시트 {{selectedYearText}}
        </BottomSheet>
        <b>ImageViewer TEST</b>
        <button type="button" style="display: block;" @click="openImageViewer">이미지뷰어 OPEN</button>
        <span>selectedIndex: {{imageSelectedIndex}}</span>
        <br>

        <b>visibilitychange TEST</b>
        <button type="button" @click="openAnotherWebview">웹뷰 추가 OPEN</button>
        <ul>
            <li v-for="state in visibilityStateLogs">{{state}}</li>
        </ul>
    </div>
    <!--@formatter:on-->
</template>

<script>
import BottomSheet from '@common/features/common/components/BottomSheet.vue';
import imageViewerSchemeUtils, {showImageViewer} from '@common/features/common/utils/imageViewerSchemeUtils.js';
import {webviewScheme} from '@common/utils/webview.js';

export default {
    name: 'Schemes',
    components: {
        BottomSheet,
    },
    data() {
        return {
            //visibilitychange
            visibilityStateLogs: [],

            //bottom sheet
            title: '학년도 선택',
            options: [
                {
                    label: '2021년',
                    value: 2021,
                },
                {
                    label: '2020년',
                    value: 2020,
                },
                {
                    label: '2019년',
                    value: 2019,
                },

            ],
            year: 2021,

            //image viewer
            imageUrls: [
                'http://image.toast.com/aaaaaaq/IAMTEACHER/o4516/s100601/d20211222/1846458655714094278.jpg',
                'http://image.toast.com/aaaaaaq/IAMTEACHER/o4516/s100601/d20211222/1846458529096989326.png',
                'http://image.toast.com/aaaaaaq/IAMTEACHER/o4516/s100601/d20211222/1846458503237606010.png',
            ],
            imageSelectedIndex: 0,
        };
    },
    computed: {
        selectedYearText() {
            return this.options.find(({value}) => value === this.year).label;
        },
    },
    methods: {
        openImageViewer() {
            imageViewerSchemeUtils.showImageViewer({
                imageUrls: this.imageUrls,
                selectedIndex: this.imageSelectedIndex,
            });

            //selectedIndex 순환
            this.imageSelectedIndex = (this.imageSelectedIndex + 1) % this.imageUrls.length;
        },

        openAnotherWebview() {
            webviewScheme('https://dev-counsel.iamservice.net');
        },
    },
    created() {
        this.visibilityStateLogs.push(document.visibilityState);
        document.addEventListener('visibilitychange', () => {
            this.visibilityStateLogs.push(document.visibilityState);
        });
    },
};
</script>

<style scoped>
.wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.wrap div {
    width: 100%;
    height: 100%;
    margin-bottom: 2rem;
}

.wrap button {
    width: 100%;
    height: 100%;
    display: inline-block;
    height: 32px;
    line-height: 32px;
    padding: 0 30px 0 13px;
    position: relative;
    border-radius: 16px;
    background-color: #eff1ff;
    color: var(--main-color);
    font-size: 13px;
    box-sizing: border-box;
    font-weight: bold;
}
</style>
