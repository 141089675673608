<template>
    <!--@formatter:off-->
    <div>
        <Services />
        <br/>
        <Schemes />
    </div>
    <!--@formatter:on-->
</template>

<script>
import Services from '@common/test/components/Services.vue';
import Schemes from '@common/test/components/Schemes.vue';

export default {
    name: 'CommonApp',
    components: {Schemes, Services},
};
</script>


