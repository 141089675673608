<template>
    <!--@formatter:off-->
    <div id="teacher" class="layer_wrap type2" v-if="show">
        <div class="layer_box">
            <div class="layer_content">
                <ul class="bx_lst_who">
                    <li v-for="service in services">
                        <span class="bx_input_rdo">
                            <input type="radio" class="input_rdo" :id="`radio-${service.serviceId}`" name="who_teacher" @change="onCheckService({serviceId: service.serviceId})">
                            <label :for="`radio-${service.serviceId}`" class="input_lbl multi">
                                {{service.serviceId}}
                                <span class="bx_cs_time">
                                    <span>/{{service.serviceId.toLowerCase()}}</span>
                                </span>
                            </label>
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <!--@formatter:on-->
</template>

<script>
import {COUNSEL_SERVICE_ID} from '@common/consts/counselServiceConsts.js';

export default {
    name: 'Services',

    data() {
        return {
            show: false,
        };
    },

    computed: {
        services() {
            const serviceIds = Object.values(COUNSEL_SERVICE_ID);
            const counselDomain = process.env.VUE_APP_COUNSEL_DOMAIN;
            const unavailableCounselServiceIds = [COUNSEL_SERVICE_ID.IAMSTUDENTPC, COUNSEL_SERVICE_ID.IAMSTUDENTAPP]; //student는 school path로 사용함

            return serviceIds
                .filter(serviceId => !unavailableCounselServiceIds.includes(serviceId))
                .map(serviceId => {
                    return {
                        serviceId,
                        url: `${counselDomain}/${serviceId.toLowerCase()}`,
                    };
                });
        },
    },

    methods: {
        onCheckService({serviceId}) {
            const serviceUrl = this.services.find(service => service.serviceId === serviceId).url;
            document.location.href = serviceUrl;
        },
    },

    created() {
        if (process.env.VUE_APP_PROFILE === 'real') {
            alert('잘못된 주소입니다.');
            return;
        }
        this.show = true;
    },
};
</script>

<style scoped>
.layer_content {
    display: flex;
}

.bx_lst_who {
    list-style: none;
}

.bx_cs_time {
    display: block;
    font-size: 13.5px;
    color: #999
}

.bx_input_rdo {
    margin-bottom: 10px;
}
</style>
