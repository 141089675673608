import Vue from 'vue';
import CommonApp from '@common/CommonApp';
import VueCompositionAPI from '@vue/composition-api';

Vue.config.productionTip = false;
Vue.use(VueCompositionAPI);

new Vue({
	render: h => h(CommonApp),
}).$mount('#app');

if (module.hot) {
	module.hot.addStatusHandler(status => {
		if (status === 'prepare') console.clear();
	});
}
