import {showImageViewerScheme} from '@common/utils/webview.js';

let ID = 0;
const FUNC_NAME_GET_IMAGES = '__IMAGE_VIEWER__getImages';
const imagesStore = {};

window[FUNC_NAME_GET_IMAGES] = (id) => {
    //todo remove
    console.info(`call ${FUNC_NAME_GET_IMAGES}, id: `, id);
    console.info(`return:`, imagesStore[id]);

    const resultJsonString = JSON.stringify(imagesStore[id]);
    unbindImageViewerImages(id);
    return resultJsonString;
};

function bindImageViewerImages({id, imageUrls, selectedIndex}) {
    imagesStore[id] = {
        imageUrls,
        selectedIndex,
    };
}

function unbindImageViewerImages(id) {
    imagesStore[id] = undefined;
}

function openImageViewer({id, imageUrls, selectedIndex}) {
    bindImageViewerImages({id, imageUrls, selectedIndex});
    showImageViewerScheme({getFuncName: FUNC_NAME_GET_IMAGES, id});
}

function showImageViewer({imageUrls, selectedIndex}) {
    const id = String(ID++);
    openImageViewer({id, imageUrls, selectedIndex});
}

export default {
    showImageViewer,
};
